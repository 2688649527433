<template>
  <section>
    <div class="max-w-screen-lg mx-auto">
      <div class="px-8 text-center">
        <h2 class="text-3xl text-body-4 mt-4">Výběr termínu</h2>
        <p
          v-if="availableDates.length && !days.length"
          class="text-17 text-body-4 mt-4 font-bold"
        >
          Vyberte volný termín z kalendáře
        </p>
      </div>
      <!-- Nejsou termíny v databázi -->
      <div v-if="!availableDates.length">
        <p class="text-17 text-body-4 mt-4 font-bold text-center">
          Momentálně nejsou žádné volné termíny pro {{ termTypeName() }}
        </p>
      </div>
      <!-- Termín byl vybrán -->
      <div v-if="days.length">
        <p class="text-17 text-body-4 mt-4 font-bold text-center">
          Termín pro {{ termTypeName() }} byl vybrán
        </p>
      </div>
      <div
        v-if="availableDates.length && !days.length"
        class="px-8 text-center font-bold select-none mt-4 min-w-max"
      >
        <div v-if="!loading == true" class="py-4">
          <vc-calendar
            class="calendar"
            is-expanded
            :attributes="attributes"
            :available-dates="availableDates"
            v-model="days"
            @dayclick="onDayClick"
          />
        </div>

        <div
          class="text-center text-body-1 font-bold grid justify-items-center"
          v-if="loading"
        >
          Probíhá načítání ...
          <img src="../../../public/images/loader.gif" />
        </div>
      </div>
      <div
        class="my-4 mx-4 md:mx-8 grid grid-cols-1 md:grid-cols-2 bg-opacity-5 rounded-md"
        :class="
          !availableDates.length
            ? 'bg-red-500 border border-red-500'
            : 'bg-body-1 border border-body-1'
        "
      >
        <div class="py-5">
          <h2 class="ml-5 text-body-4 font-bold text-17">
            {{ termTypeName() }}
          </h2>
        </div>
        <div class="py-5">
          <h2
            class="text-left md:text-right ml-5 mr-5 text-body-4 font-bold text-17"
          >
            {{ days.map((i) => $moment(i.date).format("DD. MM. YYYY"))[0] }}
          </h2>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 mx-8 mb-24 mt-12">
        <div class="duration-300">
          <button
            class="px-8 text-body-3 border border-body-3 font-bold text-15 focus:outline-none rounded-md h-14"
            @click="$router.go(-1)"
          >
            Krok zpět
          </button>
        </div>
        <div class="text-left md:text-right mt-8 md:mt-0 duration-300">
          <button
            v-if="availableDates.length && days.length"
            class="hover:opacity-80 modal-open px-8 font-bold text-15 text-body-1 focus:outline-none rounded-md h-14"
            @click="changeDay()"
          >
            Vybrat znovu
          </button>
          <button
            v-if="availableDates.length && days.length"
            class="hover:opacity-80 modal-open px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-14"
            @click="days.date ? null : save()"
          >
            Rezervovat termín
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { getProvidedTermsByType } from "../../services/rest/services";
import { createUserTerm } from "../../services/rest/services";

export default {
  name: "TermSelection",
  data() {
    return {
      currentTerms: [],
      days: [],
      availableDates: [],
      loaded: false,
      view: "",
      result: "",
      type: "",
      createTerms: [],
      loading: true,
      after14DaysTimestamp: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("main", ["currentProperty"]),
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        dates: date,
        highlight: false,
      }));
    },
  },
  methods: {
    changeDay() {
      return (this.days = []);
    },
    termTypeName() {
      const name = this.$route.name;
      if (name === "stagingTermSelection") {
        return "Příprava domácnosti na focení";
      } else if (name === "photoTermSelection") {
        return "Profesionální fotografie";
      } else if (name === "videoTermSelection") {
        return "Profesionální video";
      } else if (name === "modelTermSelection") {
        return "Profesionální 3D model";
      } else if (name === "consultationTermSelection") {
        return "Konzultace s advokátem";
      }
    },
    // this.availableDates.find(i => i === this.days.date)
    onDayClick(day) {
      if (!day.isDisabled) {
        const idx = this.days.findIndex((d) => d.id === day.id);
        if (idx >= 0) {
          this.days.splice(idx, 1);
        } else {
          this.days.push({
            id: day.id,
            date: this.$moment(day.date).format("YYYY-MM-DD"),
          });

          if (this.days.length > 1) {
            this.days.splice(idx, 1);
          }
        }
      } else {
        return;
      }
    },
    async save() {
      try {
        const savedTerm = this.availableDates.find(
          (i) => i === this.days[0].date
        );
        if (this.days.length === 0) {
          return this.$toast(this.$t("ErrorMessages.DateIsRequired"), {
            className: ["et-alert"],
          });
        } else {
          if (savedTerm) {
            this.createTerms = savedTerm;
            const name = this.$route.name;
            if (name === "stagingTermSelection") {
              this.type = "staging";
              this.createTermByType();
            } else if (name === "photoTermSelection") {
              this.type = "photo";
              this.createTermByType();
            } else if (name === "videoTermSelection") {
              this.type = "video";
              this.createTermByType();
            } else if (name === "modelTermSelection") {
              this.type = "model";
              this.createTermByType();
            } else if (name === "consultationTermSelection") {
              this.type = "consultation";
              this.createTermByType();
            }

            const type = this.type;

            if (this.$route.name !== "consultationTermSelection") {
              this.$router.push(
                {
                  name: "priprava-nemovitosti",
                  params: { type, savedTerm },
                },
                () => {
                  this.$toast(this.$t("SuccessMessages.SuccessfullySaved"), {
                    className: ["et-info screen-style"],
                  });
                }
              );
              return;
            } else
              return this.$router.push(
                { name: "pravni-sluzby", params: { type, savedTerm } },
                () => {
                  this.$toast(this.$t("SuccessMessages.SuccessfullySaved"), {
                    className: ["et-info screen-style"],
                  });
                }
              );
          } else
            return this.$toast(this.$t("ErrorMessages.DateIsRequired"), {
              className: ["et-alert screen-style"],
            });
        }
      } catch (error) {
        return this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert screen-style"],
        });
      }
    },

    async fetchStagingTerm() {
      this.type = "staging";
      this.fetchTermsByType();
    },

    async fetchPhotoTerm() {
      this.type = "photo";
      this.fetchTermsByType();
    },

    async fetchVideoTerm() {
      this.type = "video";
      this.fetchTermsByType();
    },

    async fetchModelTerm() {
      this.type = "model";
      this.fetchTermsByType();
    },

    async fetchConsultationTerm() {
      this.type = "consultation";
      this.fetchTermsByType();
    },

    async createTermByType() {
      let reservationId;
      this.currentTerms.forEach((term) => {
        if (term.Date === this.createTerms && term.Type === this.type) {
          reservationId = term.id;
        }
      });
      this.result = await createUserTerm(
        JSON.stringify({
          email: this.user.email,
          date: this.createTerms,
          type: this.type,
          propertyId: this.currentProperty.id,
          reservationId,
        })
      );
    },
    async fetchTermsByType() {
      const result = await getProvidedTermsByType(this.type);
      if (result) {
        this.currentTerms = result;
        const terms = result.map((i) => i.Date);
        if (terms) {
          this.availableDates.push(...terms);
          this.loaded = true;
          this.loading = false;
        }
      }

      // Make timestamp from this dates and compare between /after14DaysTimestamp/
      if (this.availableDates) {
        this.availableDates = this.availableDates.filter((date) => new Date(date).getTime() > this.after14DaysTimestamp);
      }
      console.log(
        "Date which are available after 14 days => ",
        this.availableDates
      );

      return;
    },
  },
  async mounted() {
    // + 14 days able to choose => compare by timestamp
    const date = new Date();
    date.setDate(date.getDate() + 14);
    this.after14DaysTimestamp = new Date(
      date.toISOString().split("T")[0]
    ).getTime();
    console.log(
      "Date can be selected after ",
      this.after14DaysTimestamp,
      " timestamp."
    );

    const name = this.$route.name;
    if (name === "stagingTermSelection") {
      return await this.fetchStagingTerm();
    } else if (name === "photoTermSelection") {
      return await this.fetchPhotoTerm();
    } else if (name === "videoTermSelection") {
      return await this.fetchVideoTerm();
    } else if (name === "modelTermSelection") {
      return await this.fetchModelTerm();
    } else if (name === "consultationTermSelection") {
      return await this.fetchConsultationTerm();
    }
  },
};
</script>

<style>
.calendar >>> span[aria-disabled="true"] {
  cursor: auto !important;
  background-color: transparent !important;
}
.calendar >>> span[aria-disabled="false"] {
  border: 2px solid #1b26e2;
}
.calendar >>> span[aria-disabled="false"]:hover {
  background-color: #1b26e2;
  color: white !important;
}
.screen-style {
  background-color: rgba(220, 38, 38, 0.8);
  position: fixed;
  border-radius: 0;
  padding: 15px;
  min-width: 100%;
  width: 100%;
}
</style>
